import React, { ReactElement } from "react";
import {
  Breadcrumbs,
  GatsbyPageProps,
  BannerHeader,
  InThisSection,
  InThisSectionLink,
  InThisSectionButton,
  FixedSharpImageFile,
} from "@ymcansw-camping/common";
import { graphql } from "gatsby";
import Layout from "../components/layout";

type PrivateBookingsProps = GatsbyPageProps<{
  readonly bannerHeaderImage: FixedSharpImageFile;
}>;

export default function PrivateBookingsPage({
  data,
}: PrivateBookingsProps): ReactElement<PrivateBookingsProps> {
  return (
    <Layout
      meta={{
        title: "Private Bookings",
        description:
          "Camp Yarramundi is the ideal location for day group conferences, business training days, team building initiatives, leadership and social group gathering.",
      }}
    >
      <BannerHeader title="Private Bookings" image={data.bannerHeaderImage} />
      <div className="page-content md-pl3 lg-pl6 group-accomodation-page">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-9 col-md-push-3">
              <div className="page-content-header">
                <div className="row">
                  <div className="col-xs-12 col-md-7 col-lg-8">
                    <Breadcrumbs items={["Private Bookings"]} />
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="content-block">
                  <h2>Escape the city and head to the bush!</h2>
                  <p>
                    Camp Yarramundi is the ideal location for day group
                    conferences, business training days, team building
                    initiatives, leadership and social group gathering.
                  </p>
                </div>

                <div className="content-block example-groups row">
                  <div className="col-xs-12 col-sm-4 col-md-5 col-md-4">
                    <div className="image-container">
                      <img
                        className="img-fluid"
                        src={require("../images/img/group-accommodation-image.jpg")}
                        alt="Private Bookings"
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-8 col-md-7 col-lg-8">
                    <h6 className="mt0">
                      Some examples of groups who book with us are:
                    </h6>
                    <ul>
                      <li>Church groups</li>
                      <li>Family reunions</li>
                      <li>Band camp</li>
                      <li>Youth groups</li>
                    </ul>
                  </div>
                </div>
                <div className="content-block">
                  <p>
                    Our conference and meeting venue caters for up to 260
                    people. We offer a selection of the standard conference
                    facilities plus on site cabin style accommodation nestled in
                    the natural bush landscape.
                  </p>
                  <p>
                    In addition to our accommodation and conference facilities
                    we have a range of outdoor adventure learning activities to
                    participate in.
                  </p>
                  <p>
                    We can also provide catering and a selection of menu options
                    that are freshly prepared by an experienced team of in-house
                    staff dedicated to providing a variety of quality,
                    nutritious meals. Our team is flexible and can cater for
                    special dietary requirements or cultural food preferences.
                    If you prefer to design your own menu, we have self-catering
                    options available.
                  </p>
                  <p>
                    Our team is proud to hold a 5 star rating by our local
                    council for food handling processes.
                  </p>
                </div>

                <div className="content-block">
                  <h2>Hire a space today for any event.</h2>
                  <p>
                    Camp Yarramundi is available to hire for social group
                    gatherings, business training days, sports groups, family
                    reunions, school fun days plus more.
                  </p>
                  <p>
                    We can tailor a package to suit your budget and requirements
                    including menu options, self-catering, adventure activities,
                    accommodation and meeting room facilities.
                  </p>
                </div>

                <div className="content-block image-content-block">
                  <div className="row">
                    <div className="col-xs-12 col-sm-4">
                      <div className="image-container">
                        <img
                          className="img-fluid"
                          src={require("../images/img/venue-hire-image1.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                      <div className="image-container">
                        <img
                          className="img-fluid"
                          src={require("../images/img/venue-hire-image2.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                      <div className="image-container">
                        <img
                          className="img-fluid"
                          src={require("../images/img/venue-hire-image3.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-3 col-md-pull-9">
              <InThisSection>
                <InThisSectionLink to="/testimonials/">
                  Testimonials
                </InThisSectionLink>
                <InThisSectionLink to="/private-bookings/">
                  Private Bookings
                </InThisSectionLink>
                <InThisSectionButton to="/contact/">
                  Booking enquiry
                </InThisSectionButton>
              </InThisSection>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query PrivateBookingsQuery {
    bannerHeaderImage: file(
      relativePath: { eq: "img/group-accommodation-bg.jpg" }
    ) {
      ...GeneralPageLayout_bannerHeaderImage
    }
  }
`;
